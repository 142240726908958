import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GeneralComponent } from './general/general.component';
import { ConozcanosComponent } from './conozcanos/conozcanos.component';
import { CentroComponent } from './centro/centro.component';
import { AutoComponent } from './auto/auto.component';
import { CapacitacionComponent } from './capacitacion/capacitacion.component';
import { BlogComponent } from './blog/blog.component';

const routes: Routes = [
  { path: '', component: GeneralComponent },
  { path: 'conozcanos', component: ConozcanosComponent },
  { path: 'centro', component: CentroComponent },
  { path: 'auto', component: AutoComponent },
  { path: 'capacitacion', component: CapacitacionComponent },
  { path: 'blog', component: BlogComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
