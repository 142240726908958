<!-- Banner -->
<div class="contenido-principal">
  <div class="video">
    <video width="600" src="../assets/images/video_cer.mp4" controls autoplay muted></video>
  </div>
  <div class="contenidoT" style="display: block;">
    <h1 class="titulo-video">
      <b style="color: black;">¡Hola! </b>
      <b style="color: #33635c">Bienvenido a CER</b>
    </h1>
    <label class="contenido-texto">Somos un centro de relacionamiento virtual y telefónico, que busca llegar al corazón de los clientes ofreciendo
      una experiencia de servicio memorable, que fomente la permanencia, la adquisición de nuevos productos y la
      recomendación de los servicios. Para lograr esto contamos con un equipo humano altamente capacitado y en constante
      entrenamiento, y recursos tecnológicos de última generación que emplean Inteligencia Artificial en la automatización
      de procesos rutinarios, para optimizar tiempo y recursos.</label>
  </div>

</div>
<!-- One -->
<section id="one" class="wrapper style2">
  <div class="superior">
    <h1 class="unidadesNegocio"><b>Unidades de Negocio</b></h1>
    <p style="color: #626262;">Encuentre aqui los servicios que ofrecemos</p>
  </div>
  <div class="inner">
    <div class="grid-style">
      <div>
        <div class="box">
          <div class="image fit">
            <a routerLink="centro"><img src="../assets/images/un3.jpg" alt="" /></a>
          </div>
          <div class="content">
            <header class="align-center header-card">
              <p class="title">Unidad de Negocio</p>
              <a routerLink="centro" style="text-decoration: none;">
                <h2>Asesoría, capacitación y orientación para el empleo</h2>
              </a>
            </header>
            <p class="contenido-card">Buscamos fortalecer las competencias del equipo humano de las diferentes empresas en Servicios, Cultura
              organizacional y Tecnología. </p>
            <footer class="align-center">
              <a class="button alt" routerLink="capacitacion">Leer mas</a>
            </footer>
          </div>
        </div>
      </div>

      <div>
        <div class="box">
          <div class="image fit">
            <a routerLink="auto"><img src="../assets/images/aut.jpg" alt="" /></a>
          </div>
          <div class="content">
            <header class="align-center header-card">
              <p class="title">Unidad de Negocio</p>
              <a routerLink="auto" style="text-decoration: none;">
                <h2>Gestión de la innovación y automatización de procesos</h2>
              </a>
            </header>
            <p class="contenido-card">Diseñamos, desarrollamos e implementamos productos tecnológicos que nos permiten garantizar mayor
              productividad y eficiencia en los procesos.</p> 
            <footer class="align-center">
              <a class="button alt" routerLink="auto">Leer mas</a>
            </footer>
          </div>
        </div>
      </div>

      <div>
        <div class="box">
          <div class="image fit">
            <a routerLink="capacitacion"><img src="../assets/images/un1.jpg" alt="" /></a>
          </div>
          <div class="content">
            <header class="align-center header-card">
              <p class="title">Unidad de Negocio</p>
              <a routerLink="capacitacion" style="text-decoration: none;">
                <h2>Acompañamiento virtual y telefónico</h2>
              </a>
            </header>
            <p class="contenido-card">Gestionamos y coordinamos todas las comunicaciones con los clientes, enmarcados dentro la estrategia del
              CRM (Customer Relationship Management).</p>
            <footer class="align-center">
              <a class="button alt"  routerLink="centro" >Leer mas</a>
            </footer>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<!-- Two -->
<section id="two" class="wrapper style3">
  <div class="inner">
    <header class="align-center">
      <p class="title">Servicio y experiencia son claves en el mundo de hoy</p>
      <h2>Centro de Experiencia y Relacionamiento CER</h2>
    </header>
  </div>
</section>

<!-- Three -->
<section id="three" class="wrapper style2">
  <div class="inner">
    <header class="align-center">
      <h2>GALERÍA</h2>
    </header>
    <p-carousel [value]="images" [circular]="true" [autoplayInterval]="3000">
      <ng-template let-img pTemplate="item" class="p-carousel">
        <div class="image fit">
          <img src="{{img.src}}" />
        </div>
      </ng-template>
    </p-carousel>
  </div>
</section>
