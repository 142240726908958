import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {

  images = [
    {
      src: '../../assets/images/img1.jpg'
    },
    {
      src: '../../assets/images/img6.jpg'
    },
    {
      src: '../../assets/images/img2.jpg'
    },
    {
      src: '../../assets/images/img3.jpg'
    },
    {
      src: '../../assets/images/img5.jpg'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
