import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GeneralComponent } from './general/general.component';
import { ConozcanosComponent } from './conozcanos/conozcanos.component';
import { CentroComponent } from './centro/centro.component';
import { AutoComponent } from './auto/auto.component';
import { CapacitacionComponent } from './capacitacion/capacitacion.component';
import { CarouselModule } from 'primeng/carousel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarModule } from 'primeng/sidebar';
import { TabMenuModule } from 'primeng/tabmenu';
import { MatTabsModule } from '@angular/material/tabs';
import { FocusTrapModule } from 'primeng/focustrap';
import { AccordionModule } from 'primeng/accordion';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { FieldsetModule } from 'primeng/fieldset';
import { MatExpansionModule } from '@angular/material/expansion';
import { TabViewModule } from 'primeng/tabview';
/**Menu reposnsivo*/
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { FlexLayoutModule } from "@angular/flex-layout";
import { BlogComponent } from './blog/blog.component';
import {PaginatorModule} from 'primeng/paginator';


@NgModule({
  declarations: [
    AppComponent,
    GeneralComponent,
    ConozcanosComponent,
    CentroComponent,
    AutoComponent,
    CapacitacionComponent,
    BlogComponent
  ],
  imports: [
    PaginatorModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    SidebarModule,
    TabMenuModule,
    MatTabsModule,
    FocusTrapModule,
    AccordionModule,
    ScrollPanelModule,
    TieredMenuModule,
    FieldsetModule,
    MatExpansionModule,
    TabViewModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
