<div class="contenido-primer-parte">
  <div class="imagen-titulo">
    <img src="../../assets/images/portatil.png" alt="imagen de capacitacion">
  </div>
  <div class="texto">
    <b>
      <p class="titulo">
        Gestión de la innovación y <br><br>
        <b style="border-bottom: 2px solid #1E645C; color: black">auto</b>matización de procesos
      </p>
    </b>
  </div>
</div>
<!-- Cuarta seccion -->
<h2 style="text-align: center;">¿Qué es RPA y RDA?</h2>
<div class="seccion-principal">
  <div class="seccion-1">
    <img src="../../assets/images/RDA.png" alt="imagen de capacitacion">
    <p style="text-align: center; font-size: 20px;"><b>RDA</b></p>
    <p><b>RDA</b>es un agente de soporte que se
      ejecuta desde el escritorio de nuestros
      ordenadores. Está pensado para que el
      <b>humano y el software trabajen conjuntamente</b>
      ya que requiere de la
      intervención humana para iniciar su labor,
      pero permitiendo que el asesor se libere de
      las tareas más mecánicas. De esta forma,
      <b>mejoramos la eficiencia</b> de nuestros
      procesos, <b>reducimos costos</b> y
      <b>aprovechamos al máximo las capacidades</b>
      de nuestros asesores.</p>
  </div>
  <div class="seccion-2">
    <img src="../../assets/images/RPA.png" alt="imagen de capacitacion">
    <p style="text-align: center; font-size: 20px;"><b>RPA</b></p>
    <p>
      <b>La Automatización Robótica de Procesos</b>,
      conocida por sus siglas en inglés <b>RPA</b> es la
      <b>tecnología que hace posible que un
      “robot” emule e integre las acciones de
      una interacción humana</b> en sistemas
      digitales para ejecutar un proceso
      comercial. Los robots emplean la interfaz
      de usuario para <b>capturar datos y
      manipular aplicaciones existentes del
      mismo modo que los humanos.</b> Estos
      robots realizan interpretaciones, activan
      respuestas y se comunican con otros
      sistemas para operar en una amplia gama
      de tareas repetitivas.
    </p>
  </div>
  <div class="seccion-3">
    <img src="../../assets/images/Servicios.png" alt="imagen de capacitacion">
    <p style="text-align: center; font-size: 20px;"><b>SERVICIOS</b></p>
    <p>
      <b>PARTNER – ENTERDEV - AGILITY</b>
      Es una plataforma que proporciona
      herramientas para la automatización de
      procesos con <b>RDA , RPA  e inteligencia
      artificial.</b> De esta forma, se  busca generar
      un modelo de negocio en la <b>creación de
      productos de alta innovación,</b> generando
      procesos e integración de plataformas en
      los mercados de alta transaccionalidad de
      información, logrando <b>beneficios de hasta
      un 80% en la optimización de tiempos,
      eficiencia, estandarización y seguridad de
      los procesos.</b> Agility ha diseñado una
      infraestructura tecnológica acorde con las
      exigencias de la solución.
    </p>
  </div>
</div>
<!-- tercera seccion -->
<section id="two" class="wrapper style3">
  <div class="inner">
    <header class="align-center">
      <p class="title">Servicio y experiencia son claves en el mundo de hoy</p>
      <h2>Centro de Experiencia y Relacionamiento CER</h2>
    </header>
  </div>
</section>
