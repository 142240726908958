<div class="contenedor">
  <div class="section_intern">
    <label>Conózcanos</label>
    <br>
    <p-accordion>
      <p-accordionTab header="¿Quiénes Somos?" [selected]="true">
        <div>
          <img class="imgs" src="../assets/images/1_quienes somos.jpg" class="img_obj" alt="" style="width: 990px; height: 380px;">
        </div>
      </p-accordionTab>
      <p-accordionTab header="Propuesta de valor">
        <div>
          <img class="imgs" src="../assets/images/2_propuesta de valor.jpg" class="img_obj" alt="" style="width: 600px; height: 300px;">
        </div>
      </p-accordionTab>
      <p-accordionTab header="Misión y Visión">
        <div>
          <img class="imgs" src="../assets/images/3_mision.jpg" class="img_obj" alt="" style="width: 700px; height: 300px;">
        </div>
        <div>
          <img class="imgs" src="../assets/images/4_visión.jpg" class="img_obj" alt="" style="width: 700px; height: 300px;">
        </div>
      </p-accordionTab>
      <p-accordionTab header="Objetivos">
        <div>
          <img class="imgs" src="../assets/images/img_obj.jpg" class="img_obj" alt="" style="width: 900px; height: 400px;">
        </div>
      </p-accordionTab>
      <p-accordionTab header="Valores corporativos">
        <div>
          <img class="imgs" src="../assets/images/img_valores.jpg" class="img_obj" alt="" style="width: 990px; height: 300px;">
        </div>
      </p-accordionTab>
      <p-accordionTab header="Pilares">
        <div>
          <img class="imgs" src="../assets/images/7_pilares.jpg" class="img_obj" alt="" style="width: 900px; height: 400px;">
        </div>
      </p-accordionTab>
      <p-accordionTab header="Portafolio de servicios">
        <div class="txt_conoz">
          Descargue aquí nuestro portafolio de servicios
        </div>
        <br>
        <a href="../assets/images/8_portafolio.pdf" class="btn-descargar" download="portafolio.pdf">DESCARGAR</a>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>
