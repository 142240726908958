<div class="contenido">
  <div class="imagen">
    <img src="../../assets/images/Capacitacion_img_principal.png" alt="imagen de capacitacion">
  </div>
  <div class="texto">
    <b>
      <p class="titulo">
        <b style="border-bottom: 2px solid #1E645C; color: black; padding-right: 5px;">Asesoría, </b>capacitación y orientación para el empleo
      </p>
    </b>
    <p class="contenido-texto-principal">
      La formación y capacitación de los empleados en toda organización es considerada uno de los
      procesos más importantes en la gestión del talento humano. En dicha perspectiva, es necesario que
      las empresas cuenten con personal capacitado y competente que establezca con los clientes una
      relación asertiva y resolutiva, la cual se destaque por ofrecer una atención desde la confianza, la
      cercanía y la pasión para así dar una respuesta pertinente y  satisfactoria a las necesidades de los 
      clientes.
      <br><br><br>
      Es por esto que en CER buscamos <b>fortalecer las competencias y habilidades del equipo humano</b> de 
      las diferentes empresas en:
      <br><br>
      • Empleo y Autoempleo 
      <br>
      • Cultura Organizacional 
      <br>
      • Servicios y Tecnología  
      <br><br>
      Nuestro equipo acompaña y monitorea el diagnóstico y el desarrollo de las actividades de 
      capacitación y entrenamiento con las cuales se empodera a los participantes en las estrategias de 
      cultura organizacional, servicios, proyectos institucionales y tecnología. De esta forma, se garantiza 
      la legitimidad de la propuesta de experiencia y relacionamiento que estos deben tener 
      con sus clientes, así como la satisfacción y productividad en su quehacer laboral.
      <br><br>  
      Para el cumplimiento de estos propósitos, realizaremos un levantamiento de la información inicial 
      con cada organización y de acuerdo a sus necesidades específicas diseñaremos un plan a medida 
      que cuente con la participación de todos los actores involucrados.
      <br><br>  
      Contamos con horarios flexibles y metodologías presenciales y virtuales.
    </p>
  </div>
</div>
<!-- Two -->
<section id="two" class="wrapper style3">
  <div class="inner">
    <header class="align-center">
      <p class="title">Servicio y experiencia son claves en el mundo de hoy</p>
      <h2>Centro de Experiencia y Relacionamiento CER</h2>
    </header>
  </div>
</section>
