import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-capacitacion',
  templateUrl: './capacitacion.component.html',
  styleUrls: ['./capacitacion.component.css']
})
export class CapacitacionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
