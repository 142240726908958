<div class="contenido">
  <h1><b>Acompañamiento virtual y telefónico</b></h1>
  <img class="imagen-atencion-cliente" src="../../assets/images/Foto - Acompañamiento virtual y telefónico.png" alt="telefono">
  <div class="seccion-1">
    <div class="contenido-1">
      <img class="imagen-atencion-cliente" src="../../assets/iconos/1. 24_7.png" alt="telefono">
      <h2>24/7</h2>
      <p>
        Gestionamos y coordinamos todas las
        comunicaciones con los clientes durante las 24
        horas del día, los 7 días de la semana.
      </p>
    </div>
    <div class="contenido-2">
      <img class="imagen-atencion-cliente" src="../../assets/iconos/2. CRM.png" alt="telefono">
      <h2>CRM</h2>
      <p>Nos enmarcamos dentro de la estrategia CRM
        (Costumer Relationship Management) e
        integramos un software especializado que
        permite coordinar todas las comunicaciones.</p>
    </div>
    <div class="contenido-2">
      <img class="imagen-atencion-cliente" src="../../assets/iconos/3. Seguimiento.png" alt="telefono">
      <h2>Seguimiento</h2>
      <p>Realizamos un seguimiento eficiente de todos
        los procesos, mediante grabaciones,
        monitoreos y análisis periódicos con el fin de
        mejorar la calidad del servicio y la satisfacció
        del cliente.</p>
    </div>
  </div>
  <div class="seccion-2">
    <div class="contenido-4">
      <img class="imagen-atencion-cliente" src="../../assets/iconos/4. Experiencia de contacto.png" alt="telefono">
      <h2>Experiencia de Contacto</h2>
      <p>
        Ayudamos a mejorar la notoriedad de las
        empresas y fortalecemos la efectividad de las
        estrategias de marketing al proporcionar a lo
        clientes una experiencia de contacto positiva.
      </p>
    </div>
    <div class="contenido-5">
      <img class="imagen-atencion-cliente" src="../../assets/iconos/5. Equipo humano.png" alt="telefono">
      <h2>Equipo humano</h2>
      <p>Nuestro equipo humano está conformado por
        jóvenes, mujeres cabeza de familia y personas
        en condición de discapacidad de zonas rurales
        que cuentan con habilidades, entrenamiento y
        capacitación para ofrecer el mejor servicio.</p>
    </div>
  </div>
  <!-- Servicios -->
  <h1 style="text-align: center;">Servicios</h1>
  <div class="servicio-1">
    <img src="../../assets/images/Fotos Servicios/1. Línea Troncal SIP.png" alt="sip">
    <div class="subServicio-1">
      <h2><b class="number">01.</b><b class="number-tittle">Línea Troncal SIP</b></h2>
      <p class="color-parrafo">Este servicio permite a las empresas con una PBX utilizar
        comunicaciones VOIP en tiempo real y conectar su central
        telefónica (ASTERISK, RCX, AVAYA, FREEPBX, etc.) a la red
        telefónica a través de internet.</p>
    </div>
  </div>
  <div class="servicio-2">
    <div class="subServicio-2 ">
      <h2><b class="number">02.</b><b class="number-tittle">PBX</b></h2>
      <p class="color-parrafo">Es una red telefónica privada utilizada dentro de una empresa
        Los usuarios de un sistema telefónico PBX (Teléfonos o
        extensiones) comparten las líneas de salida para realizar llamadas
        telefónicas externas.</p>
      </div>
      <img src="../../assets/images/Fotos Servicios/2. PBX.png" alt="pbx">
  </div>
  <div class="servicio-3">
    <img src="../../assets/images/Fotos Servicios/2. PBX.png" alt="pbx">
    <div class="subServicio-3 ">
      <h2><b class="number">02.</b><b class="number-tittle">PBX</b></h2>
      <p class="color-parrafo">Es una red telefónica privada utilizada dentro de una empresa
        Los usuarios de un sistema telefónico PBX (Teléfonos o
        extensiones) comparten las líneas de salida para realizar llamadas
        telefónicas externas.</p>
      </div>
  </div>
  <div class="servicio-1">
    <img src="../../assets/images/Fotos Servicios/3. Línea Troncal Analógica.png" alt="line">
    <div class="subServicio-1">
      <h2><b class="number">03.</b><b class="number-tittle">Línea Troncal Analógica</b></h2>
      <p class="color-parrafo">Es una central telefónica tipo PBX que utiliza una línea troncal
        doble para poder hacer la central parte de la red de otras
        centrales y mantener las comunicaciones.</p>
    </div>

  </div>
  <div class="servicio-2">
    <div class="subServicio-2">
      <h2><b class="number">04.</b><b class="number-tittle">PSTN</b></h2>
      <p class="color-parrafo">Es una red con conmutación de circuitos tradicional optimizada
        para comunicaciones de voz en tiempo real. Cuando llama
        alguien, cierra un conmutador al marcar y establece así un
        circuito con el receptor de la llamada.</p>
      </div>
      <img src="../../assets/images/Fotos Servicios/4. Chatbot.png" alt="hatbot">

  </div>
  <div class="servicio-3">
    <img src="../../assets/images/Fotos Servicios/4. Chatbot.png" alt="hatbot">
    <div class="subServicio-3">
      <h2><b class="number">04.</b><b class="number-tittle">PSTN</b></h2>
      <p class="color-parrafo">Es una red con conmutación de circuitos tradicional optimizada
        para comunicaciones de voz en tiempo real. Cuando llama
        alguien, cierra un conmutador al marcar y establece así un
        circuito con el receptor de la llamada.</p>
      </div>

  </div>
  <div class="servicio-1">
    <img src="../../assets/images/Fotos Servicios/5. PSTN.png" alt="pstn">
    <div class="subServicio-1">
      <h2><b class="number">05.</b><b class="number-tittle">Chatbot</b></h2>
      <p class="color-parrafo">Es un programa que simula mantener una conversación con
        una persona al proveer respuestas automáticas a entradas
        hechas por el usuario.</p>
    </div>

  </div>
  <div class="servicio-2">
    <div class="subServicio-2">
      <h2><b class="number">06.</b><b class="number-tittle">Plataforma de Centro de Contacto</b></h2>
      <p class="color-parrafo">Es una plataforma que permite definir perfiles para visualizar los
        diferentes módulos, calidad, agente, reportes en tiempo real e
        histórico. Posee, entre otras funciones, la distribución automática
        de llamadas.</p>
      </div>
      <img src="../../assets/images/Fotos Servicios/6. Plataforma de contacto.png" alt="plataforma">
  </div>
  <div class="servicio-3">
    <img src="../../assets/images/Fotos Servicios/6. Plataforma de contacto.png" alt="plataforma">
    <div class="subServicio-3">
      <h2><b class="number">06.</b><b class="number-tittle">Plataforma de Centro de Contacto</b></h2>
      <p class="color-parrafo">Es una plataforma que permite definir perfiles para visualizar los
        diferentes módulos, calidad, agente, reportes en tiempo real e
        histórico. Posee, entre otras funciones, la distribución automática
        de llamadas.</p>
      </div>
  </div>
</div>
<!-- Two -->
<section id="two" class="wrapper style3">
  <div class="inner">
    <header class="align-center">
      <p style="text-align: center;" class="title">Servicio y experiencia son claves en el mundo de hoy</p>
      <h2>Centro de Experiencia y Relacionamiento CER</h2>
    </header>
  </div>
</section>
