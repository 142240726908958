<!-- Header -->
<div>
  <mat-toolbar>
    <mat-toolbar-row>
      <button mat-icon-button (click)="sidenav.toggle()" class="menu-boton">
        <mat-icon>menu</mat-icon>
      </button>
      <span class="menu-spacer">
        <img class="iconoo" src="../assets/images/logocer.png" alt="" />
      </span>
      <div class="menu-header">
        <!-- The following menu items will be hidden on both SM and XS screen sizes -->
        <a mat-button routerLink="">INICIO</a>
        <a mat-button routerLink="conozcanos">CONÓZCANOS</a>
        <a mat-button routerLink="capacitacion">ASESORÍA, CAPACITACIÓN Y <br> ORIENTACIÓN PARA EL EMPLEO</a>
        <a mat-button routerLink="auto">GESTIÓN DE LA INNOVACIÓN Y <br> AUTOMATIZACIÓN DE PROCESOS</a>
        <a mat-button routerLink="centro">ACOMPAÑAMIENTO VIRTUAL Y <br> TELEFÓNICO</a>
        <a mat-button routerLink="blog">BLOG</a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #sidenav>
      <mat-nav-list>
        <button style="margin: 0 0 0 87%;"mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>close</mat-icon>
        </button>
        <a (click)="sidenav.toggle()" mat-list-item routerLink="">Inicio</a>
        <a (click)="sidenav.toggle()" mat-list-item routerLink="conozcanos">Conózcanos</a>
        <a (click)="sidenav.toggle()" mat-list-item routerLink="capacitacion">Asesoría, capacitación y orientación para el empleo</a>
        <a (click)="sidenav.toggle()" mat-list-item routerLink="auto">Gestión de la innovación y automatización de procesos</a>
        <a (click)="sidenav.toggle()" mat-list-item routerLink="centro">Acompañamiento virtual y telefónico</a>
        <a (click)="sidenav.toggle()" mat-list-item routerLink="blog">Blog</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>
      <router-outlet></router-outlet>
      <div class class="footer">
        <div class="int_contact">
          <ul>
            <label style="font-size: 25px; font-weight: bold;">Contáctenos</label>
            <li>
              <a class="icon fa-phone" style="color: white; font-size: 20px;"></a><label class="contacto">+57 (1) 929
                1986 – 314858 8077</label>
            </li>
            <li>
              <a class="icon fa-envelope" style="color: white; font-size: 15px;"></a><label
                class="contacto">gerencia@cerexperiencia.com</label>
            </li>
            <li>
              <a class="icon fa-home" style="color: white; font-size: 20px;"></a><label class="contacto">Carrera 11 número 115-40  Oficina 703 Torre B,  Azahar de la Sierra</label>
            </li>
            <ul class="icons">
              <li>
                <a href="https://www.facebook.com/micer.experiencia.9" class="icon fa-facebook"
                  style="color: white;"><span class="label">Facebook</span></a>
              </li>
              <li>
                <a href="https://twitter.com/CerExperiencia" class="icon fa-twitter" style="color: white;"><span
                    class="label">Twitter</span></a>
              </li>
              <li>
                <a href="https://www.instagram.com/cerexperienciayrelacionamiento/" style="color: white;"
                  class="icon fa-instagram"><span class="label">Instagram</span></a>
              </li>
            </ul>
          </ul>
        </div>
        <div class="int_aliados">
          <label>Aliados</label>
          <img src="../assets/images/barra_inferior.png" />
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
