<!-- contenido 1 -->
<div class="contenido-1">
  <div class="titulo-contenido">
    <h1 ><b style="color: white;">¡Hagamos grandes cosas juntos!</b></h1>
    <p><b style="color: white;">Blog / Centro de Experiencia y Relacionamiento</b></p>
  </div>
  <img src="../../assets/images/Imagenes-blog/Foto BLOG.png" alt="imagenes">
</div>
<div class="contenido-2">
  <img src="../../assets/images/Imagenes-blog/1..png" alt="">
  <div class="texto">
    <h2>Prueba</h2>
    <p><b>CER</b>/ 24 NOV 2021</p>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio similique eum est, iure eius, fuga quis aspernatur nostrum repudiandae, debitis porro sunt alias fugiat distinctio dolor praesentium ducimus quidem ipsa.</p>
    <button style="color: green !important;" mat-icon-button>
      LEER MÁS
      <mat-icon>arrow_right_alt</mat-icon>
    </button>
  </div>
  <hr>
  <img src="../../assets/images/Imagenes-blog/2. Photo.png" alt="">
  <div class="texto">
    <h2>Prueba</h2>
    <p><b>CER</b>/ 24 NOV 2021</p>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio similique eum est, iure eius, fuga quis aspernatur nostrum repudiandae, debitis porro sunt alias fugiat distinctio dolor praesentium ducimus quidem ipsa.</p>
    <button style="color: green !important;" mat-icon-button>
      LEER MÁS
      <mat-icon>arrow_right_alt</mat-icon>
    </button>
  </div>
  <hr>
  <div class="texto">
    <h2>Prueba</h2>
    <p><b>CER</b>/ 24 NOV 2021</p>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio similique eum est, iure eius, fuga quis aspernatur nostrum repudiandae, debitis porro sunt alias fugiat distinctio dolor praesentium ducimus quidem ipsa.</p>
    <button style="color: green !important;" mat-icon-button>
      LEER MÁS
      <mat-icon>arrow_right_alt</mat-icon>
    </button>
  </div>
  <hr>
  <img src="../../assets/images/Imagenes-blog/3. Photo.png" alt="">
  <div class="texto">
    <h2>Prueba</h2>
    <p><b>CER</b>/ 24 NOV 2021</p>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio similique eum est, iure eius, fuga quis aspernatur nostrum repudiandae, debitis porro sunt alias fugiat distinctio dolor praesentium ducimus quidem ipsa.</p>
    <button style="color: green !important;" mat-icon-button>
      LEER MÁS
      <mat-icon>arrow_right_alt</mat-icon>
    </button>
  </div>
</div>
<p-paginator [rows]="10" [totalRecords]="100" pageLinkSize="3"></p-paginator>
