import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  images = [
    {
      src: '../assets/images/empleate.jpg'
    },
    {
      src: '../assets/images/comfenalco.jpg'
    },
    {
      src: '../assets/images/andesbpo.jpg'
    },
    {
      src: '../assets/images/comfacundi.png'
    },
    {
      src: '../assets/images/asopagos.png'
    },
    {
      src: '../assets/images/fejecaja.png'
    },
    {
      src: '../assets/images/cofrem.jpg'
    },
    {
      src: '../assets/images/sougile.png'
    }
  ];
}
